import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/UsingSparkLayout.js";
import ComponentPreview from '../../../components/ComponentPreview';
import { SprkDivider } from '@sparkdesignsystem/spark-react';
import typingExample from '../../../images/autocomplete/Active Typing@2x.png';
import selectingExample from '../../../images/autocomplete/Selecting Options@2x.png';
import noResultsFoundExample from '../../../images/autocomplete/No Results Found@2x.png';
import noSearchIconExample from '../../../images/autocomplete/No Search Icon@2x.png';
import twoLinesExample from '../../../images/autocomplete/address_two_lines@2x.png';
import noLabelExample from '../../../images/autocomplete/no_label@2x.png';
import categoriesExample from '../../../images/autocomplete/search_across_categories@2x.png';
import recentSearchesExample from '../../../images/autocomplete/focused_without_search@2x.png';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Autocomplete`}</h1>
    <p>{`Autocomplete input fields allow the browser to predict information being
entered by the user. The browser should display options based on
previous information entered.`}</p>
    <ComponentPreview componentName="autocomplete--default-story" hasHTML hasAngular minHeight="27rem" mdxType="ComponentPreview" />
    <h3>{`Usage`}</h3>
    <p>{`Autocomplete helps users search for options that are difficult to remember or
commonly misspelled. They allow designers to include more options to choose
from, with less space.`}</p>
    <h3>{`Guidelines`}</h3>
    <p>{`Autocomplete input fields should be used:`}</p>
    <ul>
      <li parentName="ul">{`When information can be predicted from previously entered information.`}</li>
      <li parentName="ul">{`When accuracy and speed are important to the user.`}</li>
      <li parentName="ul">{`When the number of options is no longer convenient or efficient to display
in a select box.`}</li>
      <li parentName="ul">{`When a select box has too many options but can be predicted for the user
(i.e. states, countries).`}</li>
    </ul>
    <p>{`Best practices for Autocomplete input fields:`}</p>
    <ul>
      <li parentName="ul">{`Try to keep lists under 10 matching options so the user doesn't have to
scroll.`}</li>
      <li parentName="ul">{`Guide the user by highlighting the most relevant suggestion.`}</li>
      <li parentName="ul">{`Use text wrapping for longer suggestions.`}</li>
      <li parentName="ul">{`Order suggestions by relevancy for quick selection.`}</li>
      <li parentName="ul">{`Ensure the Autocomplete list is not blocking other elements on mobile.`}</li>
    </ul>
    <SprkDivider element="span" additionalClasses="sprk-u-Measure" mdxType="SprkDivider"></SprkDivider>
    <p>{`Autocomplete is commonly used for search bars where accurate, quick suggestions
help the user choose the correct data format for a task.`}</p>
    <h2>{`Variants`}</h2>
    <h3>{`Default`}</h3>
    <ComponentPreview componentName="autocomplete--default-story" hasHTML hasAngular minHeight="27rem" mdxType="ComponentPreview" />
    <h3>{`Huge`}</h3>
    <p>{`Huge Autocomplete is a larger version of the Autocomplete field.`}</p>
    <p>{`They're commonly used as a standalone feature on the page.`}</p>
    <ComponentPreview componentName="autocomplete--huge-story" hasHTML hasAngular minHeight="27rem" mdxType="ComponentPreview" />
    <h2>{`States`}</h2>
    <p>{`Each Autocomplete variant has an error and disabled state.`}</p>
    <h3>{`Error`}</h3>
    <ComponentPreview componentName="autocomplete--default-invalid" hasHTML hasAngular mdxType="ComponentPreview" />
    <h3>{`Disabled`}</h3>
    <ComponentPreview componentName="autocomplete--default-disabled" hasHTML hasAngular mdxType="ComponentPreview" />
    <h3>{`Active Typing`}</h3>
    <img className="sprk-u-mbm" src={typingExample} style={{
      "maxWidth": "40rem",
      "width": "100%"
    }} alt="As a user types into the Autocomplete input, matching results are displayed in a listbox below the input." />
    <h3>{`Selecting Option`}</h3>
    <img className="sprk-u-mbm" src={selectingExample} style={{
      "maxWidth": "40rem",
      "width": "100%"
    }} alt="The Autocomplete component with a mouse cursor highlighting a search result." />
    <h3>{`No Results Found`}</h3>
    <p>{`Provide guidance and offer suggestions if no results are found or do not match any information in the database.`}</p>
    <p>{`Create a better experience for the user by:`}</p>
    <ul>
      <li parentName="ul">{`Expressing empathy.`}</li>
      <li parentName="ul">{`Clearly communicate the state of the result.`}</li>
      <li parentName="ul">{`Offer suggestions for next steps.`}</li>
    </ul>
    <img className="sprk-u-mbm" src={noResultsFoundExample} style={{
      "maxWidth": "40rem",
      "width": "100%"
    }} alt="The Autocomplete component displaying a 'No results found' message." />
    <SprkDivider element="span" additionalClasses="sprk-u-Measure" mdxType="SprkDivider"></SprkDivider>
    <h2>{`Additional Customizations`}</h2>
    <h3>{`No Search Icon`}</h3>
    <p>{`Showing a search icon is not required if an Autocomplete is part of a larger form.`}</p>
    <p>{`Use the search icon when an Autocomplete is independent of other form elements on the page to provide clarity that this is a search field.`}</p>
    <img className="sprk-u-mbm" src={noSearchIconExample} style={{
      "maxWidth": "40rem",
      "width": "100%"
    }} alt="The Autocomplete component without the search icon." />
    <h3>{`Address - Two Lines of Text`}</h3>
    <p>{`Addresses can span two lines when displaying the full address.`}</p>
    <img className="sprk-u-mbm" src={twoLinesExample} style={{
      "maxWidth": "40rem",
      "width": "100%"
    }} alt="The Autocomplete component displaying multi-line search results." />
    <h3>{`No Label`}</h3>
    <p>{`A label is not required for this input type. Autocomplete inputs should not use a label when used in a masthead.`}</p>
    <img className="sprk-u-mbm" src={noLabelExample} style={{
      "maxWidth": "40rem",
      "width": "100%"
    }} alt="The Autocomplete component depicted without the label." />
    <h3>{`Search Across Categories`}</h3>
    <p>{`If there are different types of results for the same search term that users may need to look, then the categories can be featured at the top of the list for users to choose.`}</p>
    <img className="sprk-u-mbm" src={categoriesExample} style={{
      "maxWidth": "40rem",
      "width": "100%"
    }} alt="The Autocomplete component displaying category links at the top of the search results." />
    <h3>{`Focused - Without Search`}</h3>
    <p>{`Use a focused state when users have recently interacted with the Autocomplete input. By including terms they have searched in the past, we can help navigate to results quicker.`}</p>
    <img className="sprk-u-mbm" src={recentSearchesExample} style={{
      "maxWidth": "40rem",
      "width": "100%"
    }} alt="The Autocomplete displaying a Recent Searches list." />
    <SprkDivider element="span" additionalClasses="sprk-u-Measure" mdxType="SprkDivider"></SprkDivider>
    <h2>{`Anatomy`}</h2>
    <ul>
      <li parentName="ul">{`May have a search icon.`}</li>
      <li parentName="ul">{`Must show predictive suggestions in a listbox.`}</li>
      <li parentName="ul">{`Must have suggestions that can be selected.`}</li>
    </ul>
    <h2>{`Accessibility`}</h2>
    <h3>{`Keyboard Interactions:`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Use the `}<inlineCode parentName="p">{`Up Arrow`}</inlineCode>{` and `}<inlineCode parentName="p">{`Down Arrow`}</inlineCode>{` keys to:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Highlight suggested values.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Use the `}<inlineCode parentName="p">{`Enter`}</inlineCode>{` key to:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Selected the highlighted suggestion.`}</li>
          <li parentName="ul">{`Close the listbox.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Use the `}<inlineCode parentName="p">{`Escape`}</inlineCode>{` key to:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Close the listbox.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Use the `}<inlineCode parentName="p">{`Tab`}</inlineCode>{` key to:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Select the highlighted suggestion.`}</li>
          <li parentName="ul">{`Close the listbox and move focus to the next focused element.`}</li>
        </ul>
      </li>
    </ul>
    <h3>{`Screen Reader Interaction`}</h3>
    <ul>
      <li parentName="ul">{`When the input gains focus, it is identified as an Autocomplete.`}</li>
      <li parentName="ul">{`When search results are displayed, the number of results is announced.`}</li>
      <li parentName="ul">{`When suggestions are highlighted, they are announced.`}</li>
    </ul>
    <h3>{`Helpful Resources`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/TR/wai-aria-practices-1.1/#combobox"
        }}>{`WAI-ARIA Authoring Practices 1.1 Combobox`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/TR/wai-aria-practices-1.1/examples/combobox/aria1.1pattern/listbox-combo.html"
        }}>{`WAI-ARIA 1.1 Combobox with Listbox Popup Examples`}</a></li>
    </ul>
    <h2>{`Related Components`}</h2>
    <p>{`To show a full list of options, use a `}<a parentName="p" {...{
        "href": "https://sparkdesignsystem.com/using-spark/components/input#select-box"
      }}>{`Select Box`}</a>{`.`}</p>
    <h2>{`Research`}</h2>
    <p>{`The Autocomplete pattern is based on the principle of recognition over recall.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      